//https://codepen.io/andrewwright/pen/Aigre
@import 'main.scss';

.testimonials {
	// height: 100%;
	align-items: baseline;
	display: flex;

	flex-direction: row;
	// opcja z dwoma w rzedzie
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.testimonial {
	// opcja z dwoma w rzedzie
	border: 2px solid $primary; // TODO gold?

	border-radius: 10px;
	flex: 1 1 400px;
	//flex: 0 50%;
	//margin: 0 auto;
	margin-bottom: 20px;

	padding-bottom: 40px;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 40px;

	@include from($tablet) {
		margin-left: 20px;
		margin-right: 20px;
	}

	.testimonial-quote {
		font-size: 16px;
		position: relative;

		.quote-container {
			padding-left: 10px;
		}

		cite {
			color: $secondary;
			display: block;
			font-size: 1em;
			margin: auto 0;

			span {
				color: $secondary;
				// font-family: $serif-heavy-font;
				font-size: 1em;
				font-style: normal;
				// font-weight: 00;
				letter-spacing: 1px;
				text-shadow: 0 1px #fff;
				text-transform: uppercase;
			}
		}

		blockquote {
			/* Negate theme styles */
			background: none;
			border: 0;
			color: $secondary;

			// font-family: Georgia, serif;
			font-size: 1.5em;
			font-style: italic;
			line-height: 1.4;
			margin: 0;
			margin: 0;
			padding: 0 0 10px 0;
			position: relative;
			text-shadow: 0 1px #fff;
			z-index: 600;

			* {
				box-sizing: border-box;
			}

			p {
				color: #75808a;
				line-height: 1.4;

				&:first-child:before {
					font-family: Georgia, serif;
					color: $gold;
					content: '\201C';
					font-size: 7.5em;
					font-weight: 700;
					left: -0.2em;
					opacity: 0.3;
					position: absolute;
					text-shadow: none;
					top: -0.4em;
					z-index: -300;
				}
			}
		}

		.authorImage {
			flex: 0 0;

			img {
				border: 3px solid $secondary;
				border-radius: 50%;
				height: 120px;
				max-width: 120px;
				width: 120px;
				object-fit: cover;
			}
		}

		.authorName {
			display: flex;
			flex: 1 0;
		}
	}
}
