@import '../components/main.scss';

.testimonials-page {
	background-color: white;

	.content {
		.title {
			@include until($desktop) {
				margin-bottom: 0;
			}
		}
	}

	.full-testimonial {
		display: flex;
		flex-direction: column;
		align-content: center;
		justify-content: center;
		margin-bottom: 0;

		// h2 {
		// 	@include from($widescreen) {
		// 		margin-top: 0.5em;
		// 		margin-bottom: 1.5em;
		// 	}
		// }
		// h3, p {
		// text-align: center;
		// }

		.columns {
			.description-container {
				.signature {
					margin-left: auto;
				}
				.friendly-paragraph {
					text-align: justify;
					text-justify: inter-word;
				}
			}
		}

		&:nth-child(4n + 5) {
			.columns {
				flex-direction: row-reverse;
			}
		}

		&:nth-child(4n + 3) {
			.description-container {
				p,
				h3 {
					margin-left: 0;
					@include from($desktop) {
						padding-left: 10px;
					}
				}

				p {
					text-align: justify;
				}

				@include until($desktop) {
					.container {
						margin-bottom: 0;
					}

					.signature {
						margin-top: 0;
					}
				}
			}
		}
		img {
			object-fit: contain;
			@include until($desktop) {
				object-fit: scale-down;
				object-position: 0 50%;
				max-height: 80vh;
			}
			// position: sticky;
			// top: 30px;
		}
		.photo-container {
			display: flex;
			align-items: flex-start;
		}

		// }
	}
}
