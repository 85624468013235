@charset "utf-8";
@import './components/main.scss';

html,
body {
	overflow: visible !important; // fix double scrollbar
	// overflow-y: scroll !important;

	a,
	svg {
		-webkit-touch-callout: none !important;
		-webkit-user-select: none !important;
		-khtml-user-select: none !important;
		-moz-user-select: none !important;
		-ms-user-select: none !important;
		-webkit-tap-highlight-color: transparent !important;
		user-select: none !important;
	}
}
html {
	-webkit-font-smoothing: antialiased;
	height: 100%;
}

body {
	-webkit-font-smoothing: antialiased;
	height: 100%;
	margin: 0;
}

#root {
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/*
::-webkit-scrollbar {
  display: none;
}
*/
.App {
	@include from($desktop) {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		.app-content-container {
			flex-grow: 1;
			width: 100%;
			align-items: center;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5 {
		font-family: $serif-heavy-font;
		font-weight: 300 !important;
	}

	// .content > h1,
	// .content > h2,
	// * .content > * h3,
	// .content > h4,
	// .content > h5 {
	// 	font-family: $serif-heavy-font;
	// 	font-weight: 400;
	// 	// moze cinzel?
	// }

	// .nicer-line-height {
	// line-height: 2em;
	// }

	.friendly-paragraph {
		max-width: 40em;

		&.centered {
			margin-left: auto;
			margin-right: auto;
		}

		&.more-important {
			line-height: 1.75;
		}
		&.more-important-2 {
			line-height: 2;
		}
	}

	.columns {
		// https://github.com/jgthms/bulma/issues/1540
		margin-left: 0;
		margin-right: 0;
	}

	p {
		// there was no padding for text in mobile
		&.paragraph-padding-on-mobile {
			@include until($tablet) {
				padding-left: 10px;
				padding-right: 10px;
			}
		}
	}

	.footer {
		background-color: inherit;
		@include until($desktop) {
			padding: 1.5rem;
		}
	}

	.one-screen-high {
		@include from($desktop) {
			min-height: 100vh;
		}
	}
}
