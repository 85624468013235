@import 'main.scss';

footer {
	flex-grow: 0;
	&.footer {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.social-icons {
		display: flex;
		justify-content: center;
	}
	h2 {
		text-align: center;
	}

	p,
	strong,
	a {
		color: $dark;
	}

	&.white-theme {
		// 	background: $dark;
		a {
			// 	p,
			// 	strong {
			color: $primary !important;
			// 		&:hover {
			// 			color: $link;
			// 		}
		}
	}
}
