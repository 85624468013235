@import 'main.scss';

.modal {
	&.mobileMenu {
		align-items: center;

		@include until($tablet) {
			justify-content: flex-end;
		}

		.modal-background {
			backdrop-filter: blur(0); // start an animation
			background-color: inherit;
		}

		&.mobileMenu-enter-done {
			// backdrop-filter: blur(3px);
			.modal-background {
				backdrop-filter: blur(3px);
				// background-color: rgba(10, 10, 10, 0.86);
				background-color: rgba($secondary, 0.86);
				transition: background-color 0.4s ease 0s, backdrop-filter 0.4s ease 0s;
			}

			.modal-content {
				// padding-top: 25em;

				@include until($tablet) {
					padding-bottom: 2em;
					padding-top: 0;
				}

				a {
					color: #fff;
					transition: color 1.1s ease 0s;
				}
			}
		}

		.modal-content {
			align-items: center;
			display: flex;
			flex-direction: column;

			// padding-top: 50%;

			a {
				color: transparent;
				font-family: $serif-heavy-font;
				font-size: 1.5em;
				padding-bottom: 1em;
				padding-top: 1em;
				position: relative;

				// a {
				// color: $gold;
				&:before,
				&:after {
					position: absolute;
					-webkit-transition: all 0.35s ease;
					transition: all 0.35s ease;
				}

				&:before {
					bottom: 27px;
					display: block;
					height: 2px;
					width: 0%;
					content: '';
					background-color: $link;
				}

				&:after {
					left: 0;
					top: 0;
					padding: 0.5em 0;
					position: absolute;
					content: attr(data-hover);
					color: #ffffff;
					white-space: nowrap;
					max-width: 0%;
					overflow: hidden;
				}

				&:hover:before,
				&.current &:before {
					opacity: 1;
					width: 100%;
				}

				&:hover:after,
				.current &:after {
					max-width: 100%;
				}
				// }
			}
		}
	}
}

.navbar {
	&.custom-nav {
		flex-grow: 0;
		overflow: hidden;
		animation-duration: 1s;
		animation-fill-mode: both;
		animation-name: fadeIn;
		// filter: invert(0);
		transition-timing-function: ease-in;
		// justify-content: center;

		&.is-transparent {
			margin-bottom: 0;
			background-color: transparent;
			background-image: none;
		}

		.navbar-menu {
			flex: 1 0 50%;

			div {
				align-items: center;

				a {
					color: $gold;
					&:before,
					&:after {
						position: absolute;
						-webkit-transition: all 0.35s ease;
						transition: all 0.35s ease;
					}

					&:before {
						bottom: 0;
						display: block;
						height: 2px;
						width: 0%;
						content: '';
						background-color: $link;
					}

					&:after {
						left: 0;
						top: 0;
						padding: 0.5em 0;
						position: absolute;
						content: attr(data-hover);
						color: #ffffff;
						white-space: nowrap;
						max-width: 0%;
						overflow: hidden;
					}

					&:hover:before,
					&.current &:before {
						opacity: 1;
						width: calc(100% - 1.25em);
					}

					&:hover:after,
					.current &:after {
						max-width: 100%;
					}
				}
			}
		}

		// modal stuff
		z-index: 41;

		// &.mobileMenuActive {
		// &:not(.white-theme) {
		// filter: invert(1);
		// transition: filter 1.4s ease 0s;
		// }
		// }

		a {
			font-size: 1.2rem;
			font-weight: 400;
			line-height: 1.125;
		}

		.navbar-brand {
			z-index: 43;
			min-height: 8rem;

			@include until($desktop) {
				align-items: center;
			}

			@include from($widescreen) {
				margin-left: 2em;
				margin-right: 2em;
			}

			.navbar-item {
				margin: 0 auto; //center
				max-width: 100%;
				margin-top: 1em;

				@include until($tablet) {
					max-width: 50%;
				}

				.logo {
					max-height: 130px;
					max-width: 100%; //15em; // was 100% before i changed to name-only logo
					&:not(.no-shadow) {
						// filter: drop-shadow(1px 1px 0 black);
					}
					// &.logo-in-circle {
					// margin: 150px;
					// background-color: $secondary;
					// border-radius: 50%;
					// width: 200px;
					// height: 200px;
					// max-height: 200px;
					// }
				}
			}

			.navbar-burger {
				align-items: center;
				display: flex;

				justify-content: center;
				position: absolute;
				right: 0;

				@include from($desktop) {
					padding-bottom: 2.5rem;
					padding-left: 1rem;
				}
				@include until($desktop) {
					padding-bottom: 2.5rem;
					padding-left: 2.5rem;
				}

				&:hover {
					background-color: inherit;
				}

				svg {
					color: $gold;

					@include from($desktop) {
						height: 4rem;
						width: 4rem;

						// color: linear-gradient($gold, black);
						// #9e753f
						// #F1E59D
					}
					@include until($desktop) {
						height: 2rem;
						width: 2rem;
					}

					@include until($tablet) {
						height: 1.5rem;
						width: 1.5rem;

						filter: drop-shadow(1px 1px 0px rgb(128, 128, 128));
						// fill: none;
						// stroke: #646464;
						// stroke-width: 1px;
						// stroke-dasharray: 2, 2;
						// stroke-linejoin: round;
					}
				}
			}
		}
	}
}
