@import '../components/main.scss';

.what-we-do-page {
	h3 {
		-moz-osx-font-smoothing: grayscale;
	}

	.app-content-container {
		* p {
			@include until($desktop) {
				text-align: justify;
			}
		}
	}
	background-color: white;
	.app-content-container .content {
		.media-content {
			overflow-x: visible;
		}
		a {
			background-color: $gold;
		}
	}

	.box-icon {
		fill: $dark;
	}
	img {
		max-height: 360px;
		max-width: 330px;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}
}
