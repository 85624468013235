@import './../components/main.scss';
// @import './assets/photos/events/800/start.jpg';
.App {
	.fullscreen-box {
		height: 100vh;
		overflow-y: scroll; //handle menu

		position: relative;

		&.css-snap {
			// scroll-snap-points-y: repeat(100vh);
			scroll-snap-stop: always;
			@include until($desktop) {
				scroll-snap-type: none;
			}

			@include from($desktop) {
				scroll-snap-type: y proximity; //mandatory;
			}

			.snap-screen {
				scroll-snap-align: start;
			}
		}

		.snap-screen {
			background-color: #fff;
			min-height: 100vh;
			background-position: center;
			@include from($widescreen) {
				background-size: cover;
				background-position: center;
				background-repeat: no-repeat;
			}

			&.landing-screen {
				text-align: center;
				background-color: $secondary;
				display: flex;
				flex-direction: column;
				background-position: center 20%;
				background-repeat: no-repeat;
				background-size: cover;
				background-image: url('/photos/events/start_portrait.webp');
				&.no-webp {
					background-image: url('/photos/events/start_portrait.jpg');
				}

				@media (min-width: 800px) and (max-width: 1800px) {
					background-image: url('/photos/events/start_1960w.webp');
					&.no-webp {
						background-image: url('/photos/events/start_1960w.jpg');
					}
				}
				@media (min-width: 1801px) {
					background-image: url('/photos/events/start_4k.jpg');
				}

				.content {
					flex-grow: 1;

					a {
						animation-delay: 0.5s;
						animation-duration: 1s;
						animation-fill-mode: both;
						animation-name: fadeIn;
						transition-timing-function: ease-in;
					}

					h1 {
						animation-duration: 1s;
						animation-fill-mode: both;
						animation-name: fadeIn;
						padding-top: calc(50vh - #{$navbar-height});
						color: $primary;

						// https://css-tricks.com/multi-line-padded-text/
						// background-color: rgba(255, 255, 255, 0.35);
						// color: black;

						position: relative; // just for filter bg
						transition-timing-function: ease-in;

						strong {
							text-decoration: underline;
							// opacity: 0 ;
							// &.str-enter-done {
							// opacity: 1;
							// transition: opacity 1s ease 0s;
							// }

							// &.str-enter-exit {
							// opacity: 0;
							// transition: opacity 1s ease 0s;
							// }
						}
					}
				}
			}

			&.what-we-do {
				background-color: #fff;
				display: flex;

				flex-direction: column;
				justify-content: space-evenly;

				@include until($tablet) {
					padding-top: 3em;
				}

				h3 {
					font-size: 1.2em;
				}
				@include from($desktop) {
					height: 100vh; // if we miss that, we won't fire animation within the snap

					margin-bottom: 2em;
					margin-top: 2em;

					padding: 2em;
					padding-left: 0;
				}
				// a {
				// font-family: $serif-heavy-font;
				// color: $secondary;
				// }
				.container {
					@include until($tablet) {
						margin-bottom: 1em;
					}

					@include from($tablet) {
						padding-top: 5em;
					}

					.content {
						text-align: center;

						@include from($desktop) {
							margin-bottom: 2em;
						}
					}
				}

				.columns {
					align-items: center;
					padding-bottom: 2em;
					@include until($desktop) {
						flex-grow: 1;
					}

					figure {
						// flex: 70vw 0 1;
						$narrow-screen-width: 100vw;
						$tablet-screen-width: 50vw;
						$wide-screen-width: 50vw;
						$wide-screen-height: 50vh;

						@include from($desktop) {
							width: $tablet-screen-width;
						}

						@include from($desktop) {
							width: $wide-screen-width;
						}

						img {
							object-fit: cover;

							@include until($desktop) {
								width: $narrow-screen-width;
							}

							@include from($desktop) {
								height: $tablet-screen-width;
								opacity: 0; // animation related

								width: $tablet-screen-width;
								&.in-viewport {
									animation-duration: 1s;
									animation-fill-mode: both;
									animation-name: fadeInLeft;
									transition-timing-function: ease-in;
								}
							}

							@include from($desktop) {
								height: $wide-screen-height;
								width: $wide-screen-width;
							}
						}
					}

					.overlapping-text {
						z-index: 1;

						padding-top: 2em;
						padding-bottom: 2em;
						@include until($desktop) {
							padding-bottom: 3em;
							padding-top: 0;
							text-align: justify;
						}

						@include from($desktop) {
							background-color: $secondary_with_alpha;
							color: $primary;
							margin-left: -15%;
							margin-right: 5em;
							margin-top: 2em;
							opacity: 0; // animation related
							padding-left: calc(15% + 2em);

							padding-right: 1em;
							&.in-viewport {
								animation-delay: 0.75s;
								animation-duration: 1s;
								animation-fill-mode: both;
								animation-name: fadeIn;
								transition-timing-function: ease-in;
							}
						}

						@include from($widescreen) {
							margin-top: 2em; //mbp - zle wyglada 5em;
							padding-right: 3em;
						}

						@include until($desktop) {
							.what-we-do-text-wrapper {
								display: flex;
								flex-direction: column;
								justify-content: inherit;
								a {
									margin: auto auto;
								}
							}
						}
						a {
							color: $secondary;
							&:hover {
								color: $secondary;
							}
						}
					}
				}
			}

			&.about-me-screen {
				background-color: $secondary; //$main_pallette_pink;
				display: flex;
				@include until($desktop) {
					min-height: 10vh;
				}
				flex-direction: column;
				.container {
					flex: 1 0;
					// background-color: black;
					@include until($desktop) {
						margin: 0 0;
					}
					.columns {
						background-color: white;

						@include until($widescreen) {
							.me-photo {
								margin: 0 auto;
							}
						}
					}

					@include from($desktop) {
						padding-bottom: 3em;
						padding-top: 3em;
					}

					.content {
						// background-color: white;
						// background-color: black;
						@include until($widescreen) {
							h2 {
								text-align: center !important;
								width: 100%;
							}
							p {
								text-align: justify;
								display: flex;
								flex-direction: column;
								a {
									margin: auto auto;
								}
							}
						}

						h1,
						p {
							color: $dark !important;
							text-align: justify;
							text-justify: inter-word;
							max-width: 100vw;
						}

						display: flex;
						flex-direction: column;
						margin-bottom: 0;
						.logo-box {
							flex-grow: 1;
							display: flex;
							flex-direction: column;
							justify-content: flex-end;
							img {
								max-width: 100px;
								flex-grow: 0;
							}
						}
					}
				}
				a {
					// 	// font-family: $serif-heavy-font;
					color: $secondary;
				}
				.footer {
					background-color: white;
				}
			}

			&.testimonial-screen {
				background-color: #fff;
				display: flex;
				@include from($tablet) {
					padding: 6em;
				}

				// color: $form_blue !important;
				h2,
				p,
				span,
				cite {
					color: $dark !important;
				}
				.paragraph {
					font-family: $serif-heavy-font;
					font-size: 1.2em;
				}
				a {
					// font-family: $serif-heavy-font;
					color: $secondary;
				}
				.content {
					text-align: center;
				}

				.button {
					@include from($desktop) {
						margin-top: 2.5em;
					}
				}

				flex-direction: column;
				justify-content: space-between;
				padding: 3em 2em 2em;
			}

			&.gallery-screen {
				// scroll-snap-align: end;
				background-color: $secondary;
				// background: white;//#F4F1CD;
				// background: linear-gradient(to bottom, #D4AF37 0%, #C5A028 100%);
				background-image: url('/photos/events/gallery_teaser_portrait.jpg');

				@media (min-width: 800px) {
					background-image: url('/photos/events/gallery_teaser-1920w.webp');
					&.no-webp {
						background-image: url('/photos/events/gallery_teaser-1920w.jpg');
					}
					background-size: cover;
				}
				@include until($tablet) {
					background-position-x: -510px;
					background-position-y: -247px;
				}

				align-items: center;

				// background-position: center;
				background-repeat: no-repeat;
				display: flex;
				.notification {
					background-color: transparent;
					h3 {
						margin-top: 2.5em;
						span {
							background-color: $secondary;
							color: $primary;
						}
					}
					h2 {
						text-align: center;
					}

					a {
						font-family: $serif-heavy-font;
						color: $secondary;
					}

					.columns {
						&:nth-child(4n + 2) {
							flex-direction: row-reverse;
						}
					}
				}
			}
		}
	}
}

// TODO POMYSL
/*
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
*/
