@import '../components/main.scss';

@keyframes move {
	0% {
		transform: translateX(0px);
	}
	50% {
		transform: translateX(-250px);
	}
	100% {
		transform: translateX(0px);
	}
}

.portfolio-page {
	background-color: white;

	.container {
		@include from($desktop) {
			max-width: 830px;
		}
	}

	z-index: 5;
}
