@import './main.scss';

.cookie-notice {
	position: fixed;
	.container > .notification {
		background-color: $secondary;
		h2,
		p {
			color: $primary;
		}
		button {
			color: $secondary;
		}
	}
	z-index: 10002147483646;

	@include until($tablet) {
		bottom: 0;
		right: 0;
		left: 0;
	}
	@include from($tablet) {
		max-width: 23em;
		bottom: 10px;
		right: 30px;
	}

	border: 2px solid $primary;
	h2 {
		font-family: $serif-heavy-font;
	}
}
