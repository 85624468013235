@import '../components/main.scss';

.contact-page {
	.notification {
		background-color: white !important;
	}
	// background-color: $light;
	background-color: white;

	@include from($tablet) {
		background-size: cover;
		background-repeat: no-repeat;
	}

	.content {
		// h2 {
		// 	color: $form_blue !important;
		// }
		.input,
		textarea {
			&:not(.is-danger) {
				border: 1px solid $primary;
			}
			&::placeholder {
				color: rgba($secondary, 0.4);
			}
			// &[type='text'] {
			// 	color: $form_blue;
			// }
			// color: $form_blue;
		}
	}
	.app-content-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		.notification {
			width: 100%;
		}
	}
	.contact-page-inner {
		// backdrop-filter: blur(5px) contrast(50%) brightness(120%);
		@include from($tablet) {
			display: flex;
			flex-direction: column;
			min-height: 100vh;
		}
	}
	.react-datepicker__day--selected,
	.react-datepicker__day--keyboard-selected {
		background-color: $primary;
	}

	.select {
		select {
			border-color: $primary;

			color: rgba($secondary, 0.4);
			// .opt {
			// 	color: $form_blue;
			// }
		}

		&::after {
			border-color: $link !important;
			z-index: 0;
			// fixing missing arrow https://stackoverflow.com/questions/53118615/bulma-dropdown-arrow-isnt-shown
		}
	}
	.select .notification {
		background-color: transparent;
		padding-right: 1.25rem;
	}

	.contact-info {
		svg {
			transform: translateY(3px);
		}
	}
	.footer {
		background-color: transparent;
	}
}
