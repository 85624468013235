@import './main.scss';

$margin-top-bottom: 40px;
.divider {
	position: relative; // height: 1px;
	height: 2px;
	@include from($desktop) {
		margin-top: $margin-top-bottom;
		margin-bottom: $margin-top-bottom;
	}
	@include until($desktop) {
		margin-top: 10px;
		margin-bottom: 10px;
	}

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 5%;
		right: 5%;
		width: 90%;
		height: 1px;
		background-image: linear-gradient(to right, transparent, $gold, transparent);
		// background-image: linear-gradient(to right, transparent, rgb(48,49,51), transparent);
	}
}
