@import '../components/main.scss';

.about-me-page {
	// background-color: $secondary;
	.app-content-container {
		// @include from($widescreen) {
		// 	padding-top: 5px;
		// 	.title {
		// 		padding-bottom: 5px;
		// 	}
		// }

		// .content {
		// background-color: white;
		// }
		// background-color: $secondary;
		h1,
		p {
			// @include until($tablet) {
			// margin-top: 0.5em;
			// }
			// color: $primary; //darken($primary, 10%) !important;
			// background: -webkit-linear-gradient($gold, darken($gold, 30%));
			// -webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;

			text-align: justify;
			text-justify: inter-word;
			// @include until($desktop) {
			// text-align: justify;
			// }
		}
	}
	img {
		// max-height: 600px;
		// max-width: 400px;
	}
	.footer {
		h1,
		p,
		strong {
			// color: $primary !important;
			// background: -webkit-linear-gradient($gold, darken($gold, 20%));
			// -webkit-background-clip: text;
			// -webkit-text-fill-color: transparent;
		}
		.social-icons {
			svg {
				// fill: $primary;
			}
		}
	}
	.photo {
		margin: 0 auto;
	}
}
