.scroll-to-top {
	position: fixed;
	bottom: 10px;
	right: 50px;
	z-index: 1000;
	width: 50px;
	height: 50px;
	svg {
		width: 50px;
		height: 50px;
	}
}
