@charset "utf-8";

// my
$main_pallette_blue: #000021; // dnia 30.08.2020 zmieniony z #001d41 bo Marta stwierdzila, ze ciemniejszy jest lepszy
$main_pallette_pink: #ecc6d2;
$sunlight: #ffe1c3; // ??
$gold: lighten(#e8c172, 15%); //lighten(#e8c172, 10%); //#9f8f69;
$brighter_gold: #ebd2a0;

//their
$primary: $gold;
$secondary: $main_pallette_blue;
$secondary_with_alpha: rgba($secondary, 0.5);
$link: $gold;
$desktop: 1025px; //show mobile for iPad Pro as well

$form_blue: lighten($secondary, 10%);

$navbar-height: 10rem;
$navbar-item-hover-color: $brighter_gold;
@import url('https://fonts.googleapis.com/css?family=Cinzel&display=swap');
$serif-heavy-font: 'Cinzel', serif;

// https://bulma.io/documentation/customize/variables/
// To override any of these variables, just set them before importing Bulma.
@import 'bulma/bulma.sass';
@import 'bulma/sass/utilities/mixins';

@keyframes fadeIn {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

@keyframes fadeInLeft {
	0% {
		opacity: 0;
		transform: translate3d(-100%, 0, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.scrollTop {
	position: fixed;
	width: 100%;
	bottom: 20px;
	align-items: center;
	height: 20px;
	justify-content: center;
	z-index: 1000;
	cursor: pointer;
	animation: fadeIn 0.3s;
	transition: opacity 0.4s;
	opacity: 0.5;
}

.scrollTop:hover {
	opacity: 1;
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		transform: translate3d(0, 100%, 0);
	}

	100% {
		opacity: 1;
		transform: none;
	}
}

.effect-gold-shine {
	// background-color: #e8c172 !important;
	//https://codepen.io/colloque/pen/bDgmx
	mask-image: linear-gradient(-75deg, #000 30%, rgba(0, 0, 0, 0.6) 50%, #000 70%);
	mask-size: 200%;
	// background-color: darken($gold, 30%) !important;
	animation: shine 4s ease 2s infinite;
	mask-position: 150%;
}

@keyframes shine {
	0% {
		mask-position: 150%;
	}

	100% {
		mask-position: -50%;
	}
}

.spinner-wrapper {
	width: 100%;
	height: 100%;

	margin: 0;
	padding: 0;

	display: flex;
	align-items: center;
	justify-content: center;

	background-color: $light;
	//   background-color: #F8F1DE;
	.spinner-loader {
		width: 66px;
		height: 66px;

		animation: contanim 2s linear infinite;

		$colors: $link, $link, $link, $link;
		// $colors: #84EBBD, #4977EC, #F6BB67, #333841;
		$d: 175.6449737548828;

		svg {
			width: 100%;
			height: 100%;

			left: 0;
			top: 0;
			position: absolute;

			transform: rotate(-90deg);

			@for $i from 1 through 4 {
				&:nth-child(#{$i}) circle {
					stroke: nth($colors, $i);
					stroke-dasharray: 1, 300;
					stroke-dashoffset: 0;

					animation: strokeanim 3s calc(0.2s * (#{$i})) ease infinite;
					transform-origin: center center;
				}
			}
		}

		@keyframes strokeanim {
			0% {
				stroke-dasharray: 1, 300;
				stroke-dashoffset: 0;
			}
			50% {
				stroke-dasharray: 120, 300;
				stroke-dashoffset: -$d / 3;
			}
			100% {
				stroke-dasharray: 120, 300;
				stroke-dashoffset: -$d;
			}
		}

		@keyframes contanim {
			100% {
				transform: rotate(360deg);
			}
		}
	}
}
